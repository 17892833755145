@import '../../utils/theme.scss';

.Menu {
  width: 100%;
  height: 100%;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  border-right-width: 1px;
  border-style: solid;

  @include theme-generator('background-color', 'bg-color');
  @include theme-generator('border-color', 'cp-border');
}
