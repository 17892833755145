@import '../utils/theme';
.App {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: 50px 1fr;

  @include theme-generator('background-color', 'bg-color');
}
