/// cp -> Components
/// bg -> background
/// mn -> menu
/// ab -> action bar
$themes: (
  light: (
    bg-color: #fafafa,
    bg-color-sec: #523a8a,
    cp-border: #523a8a,
    cp-border-sec: #fafafa,
    font-color: #523a8a,
    font-color-sec: #fafafa,
  ),
  dark: (
    bg-color: #523a8a,
    bg-color-sec: #fafafa,
    cp-border: #fafafa,
    cp-border-sec: #523a8a,
    font-color: #fafafa,
    font-color-sec: #523a8a,
  ),
);

@mixin theme-generator($key, $color) {
  @each $theme-name, $theme-color in $themes {
    &.theme-#{$theme-name} {
      #{$key}: map-get(map-get($themes, $theme-name), $color);
    }
  }
}

$color-black: #050505;
$color-white: #fafafa;
$color-primary: #523a8a;
$color-light-primary: #9c94c4;
$color-secondary: #00ea29;
$color-secondary-dark: #00c023;
$color-light: #d8d6dd;
$color-error: #ff202b;
$color-warning: #ffd709;
$color-success: #20f93d; // Fonts
$font-color: #6e6e6e;
$font-size: 16px;
