@import '../../../utils/theme.scss';

.ThemePicker {
  display: grid;
  width: 100%;
  height: 100%;
  justify-items: center;
  align-items: center;
  position: relative;

  .icon-config {
    font-size: 22px;
    @include theme-generator('color', 'font-color');
  }

  .dropdown {
    position: absolute;
    top: 50px;
    right: 0px;
    transition: transform 1s;

    &.isClosed {
      transition: transform 1s;
      visibility: collapse;
      transform: translateY(-100%);
    }

    &.isOpen {
      visibility: visible;
      transform: translateY(0%);
    }

    .theme-option {
      width: 50px;
      height: 50px;
      display: grid;
      justify-items: center;
      align-items: center;
      @include theme-generator('background-color', 'bg-color-sec');

      .icon-config {
        @include theme-generator('color', 'font-color-sec');
      }
    }
  }
}
