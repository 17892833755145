@import '../../utils/theme.scss';

._Text {
  font-size: 18px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  &.title {
    font-size: 28px;
  }
  &.sub-title {
    font-size: 22px;
  }
  @include theme-generator('color', 'font-color');
}
