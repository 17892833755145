@import '../../utils/theme.scss';

.ActionBar {
  width: 100%;
  height: 100%;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  border-bottom-width: 1px;
  border-style: solid;
  padding: 0px 0px 0px 8px;
  align-items: center;

  display: grid;
  grid-template-columns: 1fr 50px;

  @include theme-generator('background-color', 'bg-color');
  @include theme-generator('border-color', 'cp-border');

  .title-aligner {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}
